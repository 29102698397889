import PropTypes from 'prop-types';
import {useState, useCallback, useEffect} from 'react';
// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Drawer from '@mui/material/Drawer';
// utils
import { fData } from 'src/utils/format-number';
import { fDateTime } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import FileThumbnail, { fileFormat } from 'src/components/file-thumbnail';
//
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerInvitedItem from './file-manager-invited-item';
import Image from "../../components/image";
import {useAxStore} from "../../store/axStore";
import {useCopyToClipboard} from "../../hooks/use-copy-to-clipboard";
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

export default function PixmeFileDetailsDrawer({
  item,
  open,
  favorited,
  //
  onFavorite,
  onCopyLink,
  onClose,
  onDelete,
  ...other
}) {
  /*
  const { original_filename, file_id, filename, res_1318, res_a4, res_full,filesize_1318,filesize_a4,filesize_full, createdAt, type} = item; */

  // const hasShared = shared && !!shared.length;
  const { copy } = useCopyToClipboard();

  const toggleTags = useBoolean(true);
  const { t, onChangeLang } = useTranslation()
  const share = useBoolean();
  const gettingDetailedFileInfo = useBoolean(false);

  const properties = useBoolean(true);
  const exif = useBoolean(true);

  const [inviteEmail, setInviteEmail] = useState('');
  const [detailedInfo, setDetailedInfo] = useState('');

    const [tags, setTags] = useState('');

  const handleChangeInvite = useCallback((event) => {
    setInviteEmail(event.target.value);
  }, []);

  const handleChangeTags = useCallback((newValue) => {
     setTags(newValue);
  }, []);

  const axStore=useAxStore()
  const user=axStore.axCurrentUser;

  useEffect(() => {
    if(open) {
      getDetailedFileInfo(item.fileid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const getDetailedFileInfo=(fid)=>{
    gettingDetailedFileInfo.onTrue()
    if(fid>0&&!gettingDetailedFileInfo.value){
      let headers={}
      const jwt = localStorage.getItem('axjwt');
      if(jwt) {
        headers = {
          'Authorization': `Bearer ${jwt}`
        }
        const params = {
          'action': 'get_detailed_file_info',
          'fid': fid
        }
        axios.get('https://api.administrix.pro/v1/get/', {
          headers,
          params
        })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;

              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
              // data.created_at_nice=ConvertToNiceDate(data.created_at)
              // data.updated_at_nice=ConvertToNiceDate(data.updated_at)

              if(data.file_detail) {
                setDetailedInfo(data.file_detail)
setTags(data.file_detail.tags)
              }
              // console.log("got detailed file info:", data);

            }else if (response.data.error){
              const {error} = response.data;
              enqueueSnackbar(`Chyba pri načítavaní dát o súbore: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
            }
          }))
          .catch(error => {
            console.log("any get_detailed_file_info fetch api error");
            console.log(error);
          })
      }
    }
    gettingDetailedFileInfo.onFalse()
  }


  const renderChipsFromCommaSeparatedString = (commaSeparatedString='',forceNotToDelete=false,tagIdsString='') => {
    const items = commaSeparatedString.split(',');
    // console.log('tagIdsString:')
    // console.log(tagIdsString)
    const tagids = (tagIdsString&&tagIdsString!=='')?tagIdsString.split(','):[];

    return (


    <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
      {items?.map((tag_item, index) => {
        let shouldRenderOnDelete=forceNotToDelete
        if (!forceNotToDelete) {
           shouldRenderOnDelete = user.ax_role < 1 || user.department_role < 3;
        } else {
           shouldRenderOnDelete = false;
        }


        return (
          <Chip
            key={index}
            label={tag_item.trim()}
            variant="outlined"
            {...(shouldRenderOnDelete && { onDelete: () => handleDeleteTag(tagids[index]) })}
          />
        );
      })}
    </Stack>

    );
  };
  const renderTags = (
    <Stack spacing={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        {t('tags')}
        <IconButton size="small" onClick={toggleTags.onToggle}>
          <Iconify
            icon={toggleTags.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Stack>

      {tags ? renderChipsFromCommaSeparatedString(tags,false,detailedInfo.tag_ids) : null}

      {(user.ax_role<1) && (
        <Autocomplete
          multiple
          freeSolo
           options={item?.tags?.map((option) => option)}
          // getOptionLabel={(option) => option}
           // defaultValue={item?.ai_tags_sk.slice(0, 3)}
           // value={tags}
          onChange={(event, newValue) => {
            handleChangeTags(newValue);
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}

          renderInput={(params) => <TextField {...params} placeholder={t('add_tag')} />}
        />
      )}
    </Stack>
  );
  const handleDeleteTag = (tagId) => {
    // console.info('You clicked the delete tag:',tagId);
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      const form_data = new FormData();
      form_data.append('tid', tagId);
      form_data.append('fid', item.fileid);
      form_data.append('action', 'delete_tag_from_file');

      axios.post('https://api.administrix.pro/v1/post/index.php',form_data,{
        headers: {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${jwt}`}
      } )
        .then((response => {

          if(response.data.meta.jwt)
            localStorage.setItem('axjwt', response.data.meta.jwt);
          if(response.data.data.success) {
            if(response.data.data.success===1){
              enqueueSnackbar('Tag sme úspešne odstránili! ', { variant: 'success' });
              getDetailedFileInfo(item.fileid);
            }else{
              enqueueSnackbar('Any error :/', { variant: 'error' });
              // console.log(error);
            }
          }else{enqueueSnackbar('Any error :/', { variant: 'error' });}

        }))
        .catch(error => {

            enqueueSnackbar('Server error at tag deletion!', { variant: 'error' });
            console.log(error);
                  })


    }


  };
  const renderProperties = (
    <Stack spacing={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        {t('properties')}
        <IconButton size="small" onClick={properties.onToggle}>
          <Iconify
            icon={properties.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Stack>
      {user.ax_role<2 &&
      <Stack direction="row" sx={{ typography: 'caption', }}>
        <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
          file_id
        </Box>
        {detailedInfo?.fileid}
      </Stack>}
      {properties.value && (
        <>
        {detailedInfo?.datetime_original&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('photography_date')}
            </Box>
            {detailedInfo?.datetime_original}
          </Stack>}
          {
            // console.log(item.res_orig)
          }
          {item?.res_orig&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {item?.res_orig}
            </Box>
            {fData(item?.filesize)}
          </Stack>}

          {item?.res_1318&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {item?.res_1318}
            </Box>
            {fData(item?.filesize_1318)}
          </Stack>}

        {item?.res_a4&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {item?.res_a4}
            </Box>
            {fData(item?.filesize_a4)}
          </Stack>}

        {item?.res_full&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {item?.res_full}
            </Box>
            {fData(item?.filesize_full)}
          </Stack>}

        {item?.createdAt&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              Created at:
            </Box>
            {fDateTime(item?.createdAt)}
          </Stack>}

        {item?.filename&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              Type
            </Box>
            {fileFormat(item?.filename)}
          </Stack>}

        {detailedInfo?.author_name&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              Autor
            </Box>
            {detailedInfo?.author_name}
          </Stack>}

        {detailedInfo?.created_at_hard&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('upload_date')}
            </Box>
            {fDateTime(detailedInfo?.created_at_hard)}
          </Stack>}


        {detailedInfo?.camera_manufacturer&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              Camera
            </Box>
            {detailedInfo?.camera_manufacturer} {detailedInfo?.camera_model}
          </Stack>}

        {detailedInfo?.exposure_time&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('exposition_time')}
            </Box>
            {detailedInfo?.exposure_time}
          </Stack>}


        {detailedInfo?.aperture&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('aperture')}
            </Box>
            {detailedInfo?.aperture}
          </Stack>}

        {detailedInfo?.iso&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              ISO
            </Box>
            {detailedInfo?.iso}
          </Stack>}


        {detailedInfo?.focal_length&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('focal_distance')}
            </Box>
            {detailedInfo?.focal_length} mm
          </Stack>}



        </>
      )}



      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        Ai tags EN
      </Stack>
        {detailedInfo?.aiCaptionEn&&<Stack direction="row" sx={{ typography: 'caption', }}>
          {detailedInfo?.aiCaptionEn}
        </Stack>}
      {detailedInfo?.ai_tags_en ? renderChipsFromCommaSeparatedString(detailedInfo.ai_tags_en, true) : null}


      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        Ai tags Local
      </Stack>
        {detailedInfo?.aiCaptionLocal&&<Stack direction="row" sx={{ typography: 'caption', }}>
          {detailedInfo?.aiCaptionLocal}
        </Stack>}
      {detailedInfo?.ai_tags_locale ? renderChipsFromCommaSeparatedString(detailedInfo.ai_tags_locale, true) : null}


    </Stack>
  );
  const renderExif = (
    <Stack spacing={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        EXIF
        <IconButton size="small" onClick={exif.onToggle}>
          <Iconify
            icon={exif.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Stack>

      {exif.value && (
        <>
        {detailedInfo?.datetime_original&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('photography_date')}
            </Box>
            {detailedInfo?.datetime_original}
          </Stack>}

        {item?.res_a4&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {item?.res_a4}
            </Box>
            {fData(item?.filesize_a4)}
          </Stack>}

        {item?.res_full&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {item?.res_full}
            </Box>
            {fData(item?.filesize_full)}
          </Stack>}

        {item?.createdAt&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              Created at:
            </Box>
            {fDateTime(item?.createdAt)}
          </Stack>}

        {item?.filename&&<Stack direction="row" sx={{ typography: 'caption', }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              Type
            </Box>
            {fileFormat(item?.filename)}
          </Stack>}
        </>
      )}
    </Stack>
  );
/*
  const renderShared = (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
        <Typography variant="subtitle2"> File Share With </Typography>

        <IconButton
          size="small"
          color="primary"
          onClick={share.onTrue}
          sx={{
            width: 24,
            height: 24,
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            '&:hover': {
              bgcolor: 'primary.dark',
            },
          }}
        >
          <Iconify icon="mingcute:add-line" />
        </IconButton>
      </Stack>

      {hasShared && (
        <Box sx={{ pl: 2.5, pr: 1 }}>
          {shared.map((person) => (
            <FileManagerInvitedItem key={person.id} person={person} />
          ))}
        </Box>
      )}
    </>
  );
*/
  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 320 },
        }}
        {...other}
      >
        <Scrollbar sx={{ height: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
            <Typography variant="h6"> {t('info')} </Typography>

            <Checkbox
              color="warning"
              icon={<Iconify icon="eva:star-outline" />}
              checkedIcon={<Iconify icon="eva:star-fill" />}
              checked={favorited}
              onChange={onFavorite}
            />
          </Stack>

          <Stack
            spacing={2.5}
            justifyContent="center"
            sx={{
              p: 2.5,
              bgcolor: 'background.neutral',
            }}
          >
            <Image  src={`${axStore.systemCurrentConfig.s3.s3_endpoint_preview}t_${item?.filename}`} sx={{ borderRadius: 2 }} />

            <Typography variant="subtitle1" sx={{ wordBreak: 'break-all' }}>
              {item?.original_filename}
            </Typography>

            <Divider sx={{ borderStyle: 'dashed' }} />

            { renderTags  }

            {renderProperties}
          </Stack>

          {/* renderShared */}
        </Scrollbar>

        {user.ax_role<1&&<Box sx={{ p: 2.5 }}>
          <Button
            fullWidth
            variant="soft"
            color="error"
            size="large"
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            onClick={onDelete}
          >
            Delete
          </Button>
        </Box>}
      </Drawer>

      <FileManagerShareDialog
        open={share.value}
        // shared={shared}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onCopyLink={onCopyLink}
        onClose={() => {
          share.onFalse();
          setInviteEmail('');
        }}
      />
    </>
  );
}

PixmeFileDetailsDrawer.propTypes = {
  favorited: PropTypes.bool,
  item: PropTypes.object,
  onClose: PropTypes.func,
  onCopyLink: PropTypes.func,
  onDelete: PropTypes.func,
  onFavorite: PropTypes.func,
  open: PropTypes.bool,
};
