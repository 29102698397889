import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import UploadIcon from '@mui/icons-material/Upload';
import React, {useEffect, useState} from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Badge from "@mui/material/Badge";
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';
import {useAuthContext} from "../../auth/hooks";
import {useAxStore} from "../../store/axStore";
import UploadDialog from "../../components/pixme-modal/pixUploadModal";
import {useUploadStore} from "../../store/pixmeUploadStore";
import Iconify from "../../components/iconify";
import PixmeShowSelectedModal from "../../modal/pixShowSelectedModal";
// import {useAxStore} from "../../store/axStore";

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
//   const axStoreDepartments = useAxStore((state) => state.axDepartments)
  const theme = useTheme();
  const axStore=useAxStore()
  const user=axStore.axCurrentUser
  const isUploading = useUploadStore((state) => state.isUploading)
  const uploadingProcess = useUploadStore((state) => state.wholeUploadProcess)
  const { t, onChangeLang } = useTranslation()
  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;
  const [isOpenUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedModalIsOpen, setSelectedModalIsOpen] = useState(false);
  const {
    selectedFiles
  } = useAxStore()


  useEffect(() => {
// console.log('header usseeffect',selectedFiles,selectedFiles.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const navigate = useNavigate();

  const handleSearchClick = () => {
    navigate('/dashboard/pixme/search');
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} url={axStore.systemCurrentConfig.logo} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >

        <IconButton aria-label="search" color="primary" onClick={handleSearchClick} title="Search...">
          <SearchIcon />
        </IconButton>


        {(user.ax_role<4&&!isOpenUploadDialog)&&<Button
          color="inherit"
          variant="contained"
          onClick={()=>{setOpenUploadDialog(true)}}
          sx={{
            textTransform:"none",
            backgroundColor:"#c72043",
            color:"white"

          }}
        >
          <Iconify icon="eva:cloud-upload-fill" sx={{marginRight:1}} />
          {/* <UploadIcon sx={{marginRight:1}} /> */}
          {!user&&<>Nahrať a vytlačiť moje fotografie online</>}
          {user&&isUploading==="ready"&&<>{t('upload')}</>}
          {isUploading==="in_progress"&&<>Uploadujem...({uploadingProcess} %)</>}
          {isUploading==="finished"&&<>Súbory nahraté <CheckCircleOutlineIcon sx={{color:"darkgreen",fontSize:"1em",marginLeft:"5px"}}/></>}
          {isUploading==="finished_with_errors"&&<>Chyba pri uploade...<ErrorOutlineIcon sx={{color:"darkgreen",fontSize:"1em",marginLeft:"5px"}}/></>}
        </Button>}





        <LanguagePopover />

        {/* (user.ax_role===0)&&<NotificationsPopover /> */}

        {/* (user.ax_role===0)&&<ContactsPopover /> */}

        {selectedFiles.length>0   &&
         <Badge badgeContent={selectedFiles.length} color="primary">
        <Tooltip title={`Máte ${selectedFiles.length} označených súborov`}>
          <TaskAltIcon sx={{color:"#76828f",cursor:"pointer"}} onClick={()=>setSelectedModalIsOpen(true)}/>
        </Tooltip>
        </Badge>
        }

        <SettingsButton />
        <UploadDialog isOpenUploadDialog={isOpenUploadDialog} setIsOpenUploadDialog={setOpenUploadDialog}  />
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
      <PixmeShowSelectedModal setSelectedModalIsOpen={setSelectedModalIsOpen} selectedModalIsOpen={selectedModalIsOpen} />
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
