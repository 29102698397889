import React from 'react';
import { Box, Button, Typography, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { MoreVert as MoreVertIcon, Upload as UploadIcon } from '@mui/icons-material';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useAxStore } from "../../store/axStore";


const actions = [
  {
    name: 'send_to_download',
    action: () => alert('Upload files'),
    icon: <UploadIcon />,
    color: 'primary',
    role: 3,
    showOnBar: true,
    showOnBarMobile: false,
  },
  {
    name: 'share_as_gallery',
    action: () => alert('Upload files'),
    icon: <UploadIcon />,
    color: 'primary',
    role: 3,
    showOnBar: false,
    showOnBarMobile: true,
  },
  {
    name: 'create_folder_from_files',
    action: () => alert('Upload files'),
    icon: <UploadIcon />,
    color: 'primary',
    role: 3,
    showOnBar: true,
    showOnBarMobile: false,
  },
  {
    name: 'move_files_to_folder',
    action: () => alert('Upload files'),
    icon: <UploadIcon />,
    color: 'primary',
    role: 3,
    showOnBar: false,
    showOnBarMobile: false,
  },
  {
    name: 'delete_from_pixme',
    action: () => alert('Clear selection'),
    icon: null,
    color: 'secondary',
    role: 3,
    showOnBar: true,
    showOnBarMobile: false,
  },
  {
    name: 'show_selected_files',
    action: () => alert('Clear selection'),
    icon: null,
    color: 'secondary',
    role: 3,
    showOnBar: false,
    showOnBarMobile: false,
  },
  {
    name: 'Deselect',
    action: () => alert('Clear selection'),
    icon: null,
    color: 'secondary',
    role: 3,
    showOnBar: false,
    showOnBarMobile: false,
  }
];

export default function SelectedFilesFloatingActionBar({ selectedFiles }) {
  const isMobileOrTablet = useMediaQuery('(max-width:768px)');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const axStore = useAxStore();
  const user = axStore.axCurrentUser;
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {selectedFiles.length > 0 && (

        <Box sx={{
          position: 'fixed',
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'calc(100% - 40px)',
          maxWidth: '1200px',
          backgroundColor: "#c20e31",
          color: "white",
          boxShadow: 3,
          borderRadius: '16px',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 1000,
          opacity: 0.9
        }}>
          <Typography variant="body1">
            {selectedFiles.length} file(s) selected
          </Typography>
          <Box>

            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {actions.map((action, index) =>
                  (user.department_role <= action.role || user.ax_role < 2) &&
                    (
                    <MenuItem key={index} onClick={() => {
                      action.action();
                      handleClose();
                    }}>
                      {action.icon} {/* Icon here if available */}
                      {t(`fltslct.${action.name}`)}
                    </MenuItem>
                  )
              )}
            </Menu>

            {!isMobileOrTablet &&
              actions.map((action, index) =>
                  (user.department_role <= action.role || user.ax_role < 2) &&
                  action.showOnBar && (
                    <Button
                      key={index}
                      variant="contained"
                      color={action.color}
                      sx={{ mx: 1 }}
                      onClick={action.action}
                      startIcon={action.icon}
                    >
                      {t(`fltslct.${action.name}`)}
                    </Button>
                  )
              )
            }

          {isMobileOrTablet &&
              actions.map((action, index) =>
                  (user.department_role <= action.role || user.ax_role < 2) &&
                  action.showOnBarMobile && (
                    <Button
                      key={index}
                      variant="contained"
                      color={action.color}
                      sx={{ mx: 1 }}
                      onClick={action.action}
                      startIcon={action.icon}
                    >
                      {t(`fltslct.${action.name}`)}
                    </Button>
                  )
              )
            }


            <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick}>
              <MoreVertIcon style={{ color: "white" }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
}

SelectedFilesFloatingActionBar.propTypes = {
  selectedFiles: PropTypes.array,
};
